@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500&family=Open+Sans&family=Oswald&family=Roboto&display=swap");

.font-josefin-sans {
  font-family: "Josefin Sans", sans-serif;
}
.font-open-sans {
  font-family: "Open Sans", sans-serif;
}
.font-oswald {
  font-family: "Oswald", sans-serif;
}
.font-roboto {
  font-family: "Roboto", sans-serif;
}

* {
  padding: 0px;
  margin: 0px;
  outline: none !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

.floatingBtn{
	position:fixed;
	width:50px;
	height:50px;
	bottom:10px;
	right:10px;
	background-color:#25d366;
	border:3px solid #fff;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  display:flex;
  justify-content:center;
  align-items:center;
  z-index:100;
  transition:0.3s;
}

.floatingBtn:hover{
  transform: rotateZ(360deg);
	border:3px solid #25d366;
	color:#25d366;
	background-color:#fff;
}