.cartProduct__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 8px lightgray;
  border-radius: 15px;
  padding: 10px;
  margin: 20px 5px;
}

.cartProduct__img img {
  width: 80px;
  height: 110px;
  margin-right: 25px;
}

.cartProduct__title {
  font-size: 1.1em;
}

.cartProduct__author {
  font-size: 0.9em;
}

.cartProduct__product {
  width: 50%;
}

.cartProduct__price {
  font-size: 1.5em;
  color: #d8024d;
  margin-top: 10px;
  font-family: "Oswald", sans-serif;
}

.cartProduct__qty {
  font-size: 1.5em;
  font-weight: bold;
  color: darkblue;
  font-family: "Open Sans", sans-serif;
}

.cartProduct__product {
  display: flex;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .cartProduct__img img {
    width: 40px;
    height: 70px;
    margin-right: 10px;
  }
  .cartProduct__title {
    font-size: 0.8em;
  }
  .cartProduct__author {
    font-size: 0.7em;
  }
  .cartProduct__product {
    width: 60%;
  }
  .cartProduct__price {
    font-size: 1.2em;
  }
  .cartProduct__qty {
    font-size: 0.9em;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .cartProduct__img img {
    width: 50px;
    height: 80px;
    margin-right: 10px;
  }
  .cartProduct__title {
    font-size: 0.9em;
  }
  .cartProduct__author {
    font-size: 0.8em;
  }
  .cartProduct__product {
    width: 65%;
  }
  .cartProduct__price {
    font-size: 1.3em;
  }
  .cartProduct__qty {
    font-size: 1.3em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .cartProduct__img img {
    width: 60px;
    height: 90px;
    margin-right: 15px;
  }
  .cartProduct__title {
    font-size: 1em;
  }
  .cartProduct__author {
    font-size: 0.9em;
  }
  .cartProduct__product {
    width: 65%;
  }
  .cartProduct__price {
    font-size: 1.4em;
  }
  .cartProduct__qty {
    font-size: 1.4em;
  }
}
