.bookDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.bookDetails__container {
  display: flex;
  max-width: 1200px;
}

.bookDetails__image {
  width: 290px;
  height: 360px;
  padding: 8px 30px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 8px lightgray;
  margin: 20px;
}

.bookDetails__details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
}

.bookDetails__details > * {
  margin-top: 3px;
  margin-bottom: 3px;
}

.bookDetails__title {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 2em;
}

.bookDetails__author {
  font-size: 1.3em;
  font-style: italic;
  color: dimgray;
}

.bookDetails__publisher {
  font-size: 1.2em;
  font-family: "Oswald", sans-serif;
  color: dimgray;
}

.bookDetails__price {
  font-size: 2.8em;
  border-bottom: 2px solid #f50057;
  color: #d8024d;
  margin-top: 10px;
  font-family: "Oswald", sans-serif;
}

.bookDetails__btns {
  margin-top: 20px;
}

.bookDetails__btns button {
  margin: 10px;
}

@media (max-width: 576px) {
  .bookDetails__container {
    flex-direction: column;
  }
}
