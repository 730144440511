.servicesPage__banner {
  width: 100%;
  filter: brightness(1);
  position: sticky;
}
.servicesPage__description {
  font-size: 1.2em;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: justify;
  font-family: "Open sans";
}

.servicesPage h2 {
  text-align: center;
  font-family: "josefin sans";
}

.servicesPage__getButtonContainer {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
