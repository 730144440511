@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500&family=Open+Sans&family=Oswald&family=Roboto&display=swap);
.font-josefin-sans {
  font-family: "Josefin Sans", sans-serif;
}
.font-open-sans {
  font-family: "Open Sans", sans-serif;
}
.font-oswald {
  font-family: "Oswald", sans-serif;
}
.font-roboto {
  font-family: "Roboto", sans-serif;
}

* {
  padding: 0px;
  margin: 0px;
  outline: none !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

.floatingBtn{
	position:fixed;
	width:50px;
	height:50px;
	bottom:10px;
	right:10px;
	background-color:#25d366;
	border:3px solid #fff;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content:center;
          justify-content:center;
  -webkit-align-items:center;
          align-items:center;
  z-index:100;
  transition:0.3s;
}

.floatingBtn:hover{
  -webkit-transform: rotateZ(360deg);
          transform: rotateZ(360deg);
	border:3px solid #25d366;
	color:#25d366;
	background-color:#fff;
}
.home {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.home__carouselItem img {
  width: 100%;
}

.home__bookCarouselLabelHeading {
  text-shadow: 1px 1px 1px gray;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.4em;
}

.home__getRecommendationContainer,
.home__authorOfTheMonthContainer,
.home__giveReviewContainer,
.home__requestBookContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  color: white;
  padding: 40px 0px;
  font-family: "Josefin Sans";
  margin-bottom: 20px;
}

.home__getRecommendationContainer {
  background: url(/static/media/home_parallax_bg_1.b9300cda.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home__authorOfTheMonthContainer {
  background: url(/static/media/home_parallax_bg_2.011eef88.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home__giveReviewContainer {
  background: url(/static/media/home_parallax_bg_3.27eec869.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home__requestBookContainer {
  background: url(/static/media/home_parallax_bg_4.29bbefe9.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home__getRecommendationContainer .home__heading,
.home__authorOfTheMonthContainer .home__heading,
.home__giveReviewContainer .home__heading,
.home__requestBookContainer .home__heading {
  font-size: 3em;
  text-align: center;
  font-weight: bold;
}

.home__getRecommendationContainer p,
.home__giveReviewContainer p,
.home__requestBookContainer p {
  font-size: 2em;
  margin-top: 10px;
  text-align: center;
}

.home__partedBoxButton {
  font-size: 1.4em;
}

.home__authorOfTheMonthContainer .home__authorOfTheMonthImg {
  max-width: 200px;
  -webkit-clip-path: ellipse(50% 50% at 50% 50%);
          clip-path: ellipse(50% 50% at 50% 50%);
  border: 4px solid lightgray;
}

.home__authorInfoContainer {
  display: -webkit-flex;
  display: flex;
  width: 40%;
}

.home__authorInfoContainer > div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.home__authorInfoContainer .home__authorOfTheMonthName {
  font-size: 1.8em;
}

.home__authorInfoContainer .home__authorOfTheMonthDescription {
  font-size: 1.2em;
  line-height: 135%;
}

.home__authorBooksContainer {
  width: 60%;
}

.home__authorOfTheMonthContainer > div {
  width: 98%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

.HomePublisherItem {
  width: 180px;
  height: 180px;
  padding: 7px;
  border: 2px solid lightgray;
  border-radius: 25px;
}

.HomePublisherItem img {
  width: 100%;
  height: 100%;
}

.home__needHelpContactUs {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}
.home__needHelpContactUs span {
  font-size: 1.8em;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .home__bookCarouselLabelHeading {
    font-size: 0.9em;
  }
  .home__getRecommendationContainer .home__heading,
  .home__authorOfTheMonthContainer .home__heading,
  .home__giveReviewContainer .home__heading,
  .home__requestBookContainer .home__heading {
    font-size: 1.6em;
  }

  .home__getRecommendationContainer p,
  .home__giveReviewContainer p,
  .home__requestBookContainer p {
    font-size: 1em;
  }
  .home__partedBoxButton {
    font-size: 0.8em;
  }
  .home__authorInfoContainer .home__authorOfTheMonthName {
    font-size: 1.2em;
  }
  .home__authorInfoContainer .home__authorOfTheMonthDescription {
    font-size: 0.8em;
  }
  .home__authorOfTheMonthContainer > div {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .home__authorBooksContainer {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 20px;
  }
  .home__authorInfoContainer {
    width: 95%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
  }
  .home__authorOfTheMonthContainer .home__authorOfTheMonthImg {
    width: 90px;
  }
  .HomePublisherItem {
    width: 90px;
    height: 90px;
  }
  .home__needHelpContactUs span {
    font-size: 1.2em;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .home__bookCarouselLabelHeading {
    font-size: 1em;
  }
  .home__getRecommendationContainer .home__heading,
  .home__authorOfTheMonthContainer .home__heading,
  .home__giveReviewContainer .home__heading,
  .home__requestBookContainer .home__heading {
    font-size: 2.5em;
  }

  .home__getRecommendationContainer p,
  .home__giveReviewContainer p,
  .home__requestBookContainer p {
    font-size: 1.4em;
  }
  .home__partedBoxButton {
    font-size: 1em;
  }
  .home__authorInfoContainer .home__authorOfTheMonthName {
    font-size: 1.4em;
  }
  .home__authorInfoContainer .home__authorOfTheMonthDescription {
    font-size: 0.9em;
  }
  .home__authorOfTheMonthContainer > div {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .home__authorBooksContainer {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 20px;
  }
  .home__authorInfoContainer {
    width: 95%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
  }
  .home__authorOfTheMonthContainer .home__authorOfTheMonthImg {
    width: 150px;
  }
  .HomePublisherItem {
    width: 120px;
    height: 120px;
  }
  .home__needHelpContactUs span {
    font-size: 1.4em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .home__bookCarouselLabelHeading {
    font-size: 1.1em;
  }
  .home__getRecommendationContainer .home__heading,
  .home__authorOfTheMonthContainer .home__heading,
  .home__giveReviewContainer .home__heading,
  .home__requestBookContainer .home__heading {
    font-size: 2.6em;
  }

  .home__getRecommendationContainer p,
  .home__giveReviewContainer p,
  .home__requestBookContainer p {
    font-size: 1.6em;
  }
  .home__partedBoxButton {
    font-size: 1.2em;
  }
  .home__authorInfoContainer .home__authorOfTheMonthName {
    font-size: 1.5em;
  }
  .home__authorInfoContainer .home__authorOfTheMonthDescription {
    font-size: 1.1em;
  }
  .home__authorBooksContainer {
    width: 50%;
  }
  .home__authorInfoContainer {
    width: 50%;
  }
  .HomePublisherItem {
    width: 150px;
    height: 150px;
  }
  .home__needHelpContactUs span {
    font-size: 1.6em;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home__bookCarouselLabelHeading {
    font-size: 1.3em;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
}

@media (min-width: 1400px) {
}

.homeBookListCarousel {
  margin-bottom: 15px;
}

.homeBookListCarousel__label {
  text-shadow: 1px 1px 1px gray;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.4em;
  text-transform: uppercase;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .homeBookListCarousel__label {
    font-size: 0.9em;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .homeBookListCarousel__label {
    font-size: 1em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .homeBookListCarousel__label {
    font-size: 1.1em;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .homeBookListCarousel__label {
    font-size: 1.3em;
  }
}
.ProductCard {
  width: 270px;
  height: 170px;
  font-family: "Roboto", sans-serif;
  box-shadow: 0px 0px 8px gray;
  border-radius: 7px;
  margin: 10px;
  padding: 10px;
  background-color: white;
}

.ProductCard__container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
}

.ProductCard__btn {
  font-family: "Oswald", sans-serif;
}

.link {
  text-decoration: none;
  color: inherit;
}

.ProductCard__detail {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-left: 10px;
  max-width: 60%;
}

.ProductCard__img {
  width: 40%;
}

.ProductCard__img img {
  width: 100%;
  height: 100%;
}

.ProductCard .ProductCard__title {
  color: #2d3436;
  font-size: 1em;
}

.ProductCard .ProductCard__author {
  color: #636e72;
  font-size: 0.9em;
}

.ProductCard .ProductCard__title,
.ProductCard .ProductCard__author {
  font-family: "Roboto", sans-serif;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .ProductCard {
    width: 160px;
    height: 120px;
  }
  .ProductCard .ProductCard__title {
    font-size: 0.7em;
  }
  .ProductCard .ProductCard__author {
    font-size: 0.6em;
  }
  .ProductCard__btn {
    font-size: 0.7em;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .ProductCard {
    width: 180px;
    height: 120px;
  }
  .ProductCard .ProductCard__title {
    font-size: 0.8em;
  }
  .ProductCard .ProductCard__author {
    font-size: 0.7em;
  }
  .ProductCard__btn {
    font-size: 0.8em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .ProductCard {
    width: 200px;
    height: 120px;
  }
  .ProductCard .ProductCard__title {
    font-size: 0.8em;
  }
  .ProductCard .ProductCard__author {
    font-size: 0.7em;
  }
  .ProductCard__btn {
    font-size: 0.8em;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ProductCard {
    width: 230px;
    height: 140px;
  }
  .ProductCard .ProductCard__title {
    font-size: 0.9em;
  }
  .ProductCard .ProductCard__author {
    font-size: 0.8em;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
}

@media (min-width: 1400px) {
}

.popupModal {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  top: 0px;
  z-index: 2000;
  background-color: #0000008e;
  font-family: "Roboto";
}

.popupModal__box {
  border-radius: 20px;
  background-color: whitesmoke;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 25px 12px;
  max-width: 545px;
  box-shadow: 0px 0px 25px black;
}

.popupModal__box span {
  font-weight: bold;
  margin: 0px 10px;
}

.cart__heading {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 2.5em;
  color: #d8024d;
  font-family: "Oswald", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 25px;
}

.cart__heading span {
  border-bottom: 2px solid #d8024d;
}

.cart__cartEmpty {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.cart__cartEmptyLine1 {
  font-size: 2em;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
}

.cart__cartEmptyLine2 {
  font-size: 1.8em;
  text-shadow: 2px 2px 1px #ce7b98;
  font-family: "Josefin Sans", sans-serif;
  cursor: pointer;
}

.cart__cartEmptyLine2:hover {
  font-weight: bold;
}

.cart__loading {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 50px;
  padding-bottom: 100px;
}

.cart_actionBtnContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.cart_actionBtnContainer > * {
  width: 140px;
}

.cart__productsContainer {
  display: -webkit-flex;
  display: flex;
  margin: 20px;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.cart_subtotalInfo {
  margin: 10px;
  padding: 12px;
  background-color: #eeeeee;
  border-radius: 10px;
  box-shadow: 0px 0px 10px lightgray;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 300px;
}

.cart_subtotalInfo > * {
  margin-top: 5px;
  margin-bottom: 5px;
}

.cart__productList {
  width: 100%;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .cart__heading {
    font-size: 2em;
  }
  .cart__cartEmptyLine1 {
    font-size: 1em;
  }
  .cart__cartEmptyLine2 {
    font-size: 0.8em;
  }
  .cart__productsContainer {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .cart_subtotalInfo {
    margin: 2px;
    padding: 6px;
    border-radius: 8px;
    max-width: 260px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .cart__heading {
    font-size: 2.3em;
  }
  .cart__productsContainer {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
.cartProduct__container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0px 0px 8px lightgray;
  border-radius: 15px;
  padding: 10px;
  margin: 20px 5px;
}

.cartProduct__img img {
  width: 80px;
  height: 110px;
  margin-right: 25px;
}

.cartProduct__title {
  font-size: 1.1em;
}

.cartProduct__author {
  font-size: 0.9em;
}

.cartProduct__product {
  width: 50%;
}

.cartProduct__price {
  font-size: 1.5em;
  color: #d8024d;
  margin-top: 10px;
  font-family: "Oswald", sans-serif;
}

.cartProduct__qty {
  font-size: 1.5em;
  font-weight: bold;
  color: darkblue;
  font-family: "Open Sans", sans-serif;
}

.cartProduct__product {
  display: -webkit-flex;
  display: flex;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .cartProduct__img img {
    width: 40px;
    height: 70px;
    margin-right: 10px;
  }
  .cartProduct__title {
    font-size: 0.8em;
  }
  .cartProduct__author {
    font-size: 0.7em;
  }
  .cartProduct__product {
    width: 60%;
  }
  .cartProduct__price {
    font-size: 1.2em;
  }
  .cartProduct__qty {
    font-size: 0.9em;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .cartProduct__img img {
    width: 50px;
    height: 80px;
    margin-right: 10px;
  }
  .cartProduct__title {
    font-size: 0.9em;
  }
  .cartProduct__author {
    font-size: 0.8em;
  }
  .cartProduct__product {
    width: 65%;
  }
  .cartProduct__price {
    font-size: 1.3em;
  }
  .cartProduct__qty {
    font-size: 1.3em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .cartProduct__img img {
    width: 60px;
    height: 90px;
    margin-right: 15px;
  }
  .cartProduct__title {
    font-size: 1em;
  }
  .cartProduct__author {
    font-size: 0.9em;
  }
  .cartProduct__product {
    width: 65%;
  }
  .cartProduct__price {
    font-size: 1.4em;
  }
  .cartProduct__qty {
    font-size: 1.4em;
  }
}

.searchResults__container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  margin: 20px;
}

.searchResults__container > * {
  display: inline-block;
}

.searchResults__notFound {
  display: -webkit-flex;
  display: flex;
  font-size: 1.9em;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 80px 0px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.searchResults__loading {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 200px;
  padding-bottom: 200px;
}
.footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: "Century Gothic";
  margin-top: 20px;
  width: 100%;
}

.footer__containerOne {
  background-color: #b30d2b;
  color: white;
  width: 100%;
  padding: 20px 0px;
  margin-bottom: 10px;
}

.footer__containerOne .footer__featuresContainer a,
.footer__containerOne .footer__contactContainer div {
  margin: 4px 0px;
}

.footer__containerOne .footer__mapContainer {
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.footer__containerOne .footer__contactContainer {
  width: 80%;
}

.footer__containerOne .footer__featuresContainer,
.footer__containerOne .footer__mapContainer,
.footer__containerOne .footer__contactContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer .footer__containerHeading {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.footer__containerOne .footer__contactContainer .footer__contactSocialMedia {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  font-size: 2em;
}

.footer__containerOne a:hover {
  color: #d1d1d1;
}

.footer__mapContainer div img {
  width: 100%;
}

.footer__containerOne .footer__featuresContainer {
  margin-left: 50px;
}

@media (max-width: 599px) {
  .footer__containerOne .footer__featuresContainer a,
  .footer__containerHeading {
    text-align: center;
  }
  .footer__containerOne .footer__featuresContainer {
    margin-left: 0px;
  }
  .footer__containerOne .footer__contactContainer,
  .footer__containerHeading {
    width: 100%;
  }
}

.footerSmall__codefumesAttribution,
.footerSmall__codefumesAttribution > strong > a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.footerSmall__codefumesAttribution > strong > a {
  opacity: 0.95;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.1em;
  text-transform: uppercase;
}

.footerSmall__codefumesAttribution > strong > a:hover {
  opacity: 1;
}

.codefumesIcon__svgIcon > .cls-1 {
  fill: none;
  stroke: #010101;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.codefumesIcon__svgIcon > .cls-2 {
  fill: #010101;
}
.codefumesIcon__svgIcon > .cls-3 {
  isolation: isolate;
  font-size: 15.68px;
  fill: #fff;
  font-family: Consolas-Bold, Consolas;
  font-weight: 700;
}

.codefumesIcon__svgIcon {
  display: inline;
}

.header {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  background-color: #f9f9f9;
  z-index: 5;
  top: 0;
}

.header__containerOne {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100%;
}

.header__containerOne .header__iconContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  width: 30%;
}

.header__containerOne .header__iconContainer div,
.header__xsSideMenuIcon Button div {
  display: -webkit-flex;
  display: flex;
  color: #2d3436;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 0.8em;
  margin-top: 3px;
}

.header__containerTwo div {
  background-color: #2d3436;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.header__containerTwo Button {
  color: white;
}

.header__logo {
  width: 300px;
  margin-top: 8px;
  margin-bottom: 3px;
}

.header__containerSearch {
  border-radius: 30px;
  margin-bottom: 5px;
  padding: 0px 5px;
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  border: 2px solid lightgray;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes search-bar-anim-open {
  from {
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
            clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    height: 0px;
    display: none;
  }
  to {
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    height: 28px;
    display: block;
  }
}

@keyframes search-bar-anim-open {
  from {
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
            clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    height: 0px;
    display: none;
  }
  to {
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    height: 28px;
    display: block;
  }
}

@-webkit-keyframes search-bar-anim-close {
  to {
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
            clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    height: 0px;
    display: block;
  }
  from {
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    height: 28px;
    display: none;
  }
}

@keyframes search-bar-anim-close {
  to {
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
            clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    height: 0px;
    display: block;
  }
  from {
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    height: 28px;
    display: none;
  }
}

.header__xsSideMenuIcon {
  display: none;
  width: 100%;
  color: #2d3436;
}

.header__xsIconContainer {
  -webkit-align-items: center;
          align-items: center;
  display: none;
}

.header__drawerInner div {
  color: #2d3436;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .header__xsIconContainer {
    display: -webkit-flex;
    display: flex;
  }
  .header__logo {
    width: 100%;
    margin-left: 5px;
  }
  .header__containerOne .header__iconContainer {
    display: none;
  }
  .header__containerTwo {
    display: none;
  }
  .header__containerSearch {
    margin-left: 0px;
    margin-right: 0px;
  }
  .header__xsSideMenuIcon {
    display: block;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .header__logo {
    width: 275px;
  }
  .header__containerOne .header__iconContainer div {
    font-size: 0.7em;
  }
  .header__containerSearch {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .header__containerSearch {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .header__containerSearch {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .header__containerSearch {
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media (min-width: 1400px) {
  .header__containerSearch {
    margin-left: 100px;
    margin-right: 100px;
  }
}

.alertLine {
  color: white !important;
  text-align: center;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  top: 0px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1400;
}

@media (max-width: 767px) {
  .cartBoxOnPageContainer {
    display: none !important;
  }
}

.headerMenuItem span {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  justify-self: center;
  font-size: 1.2em;
  background-color: transparent;
  transition: all 0.4s;
}

@media (min-width: 576px) and (max-width: 767px) {
  .headerMenuItem span {
    font-size: 0.9em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .headerMenuItem span {
    font-size: 1em;
  }
}

.headerMenuItem:hover {
  background-color: rgb(9, 132, 227);
  transition:all 0.4s;
}

.cartBoxOnPage {
  position: absolute !important;
  height: 385px;
  width: 300px;
  background-color: white;
  top: 60px;
  right: 30px;
  padding: 12px;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.cartBox__item {
  display: -webkit-flex;
  display: flex;
}
.cartBox__item .price {
  color: rgb(163, 32, 65);
}
.cartBox__item img {
  width: 40px;
  height: 70px;
  margin-right: 5px;
}

.cartItemsContainer {
  overflow-y: scroll;
  height: 293px;
  margin-bottom: 7px;
}
.yourCart {
  color: rgb(163, 32, 65);
  margin-left: 10px;
}

.bookDetails {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.bookDetails__container {
  display: -webkit-flex;
  display: flex;
  max-width: 1200px;
}

.bookDetails__image {
  width: 290px;
  height: 360px;
  padding: 8px 30px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 8px lightgray;
  margin: 20px;
}

.bookDetails__details {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin: 20px;
}

.bookDetails__details > * {
  margin-top: 3px;
  margin-bottom: 3px;
}

.bookDetails__title {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 2em;
}

.bookDetails__author {
  font-size: 1.3em;
  font-style: italic;
  color: dimgray;
}

.bookDetails__publisher {
  font-size: 1.2em;
  font-family: "Oswald", sans-serif;
  color: dimgray;
}

.bookDetails__price {
  font-size: 2.8em;
  border-bottom: 2px solid #f50057;
  color: #d8024d;
  margin-top: 10px;
  font-family: "Oswald", sans-serif;
}

.bookDetails__btns {
  margin-top: 20px;
}

.bookDetails__btns button {
  margin: 10px;
}

@media (max-width: 576px) {
  .bookDetails__container {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.checkout__heading {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 2.5em;
  color: #d8024d;
  font-family: "Oswald", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 25px;
}

.checkout__heading span {
  border-bottom: 2px solid #d8024d;
}

.checkout__checkoutEmpty {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.checkout__checkoutEmptyLine1 {
  font-size: 2em;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
}

.checkout__checkoutEmptyLine2 {
  font-size: 1.8em;
  text-shadow: 2px 2px 1px #ce7b98;
  font-family: "Josefin Sans", sans-serif;
  cursor: pointer;
}

.checkout__checkoutEmptyLine2:hover {
  font-weight: bold;
}

.checkout__loading {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 50px;
  padding-bottom: 100px;
}

.checkout_actionBtnContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.checkout_actionBtnContainer > * {
  width: 140px;
}

.checkout__productsContainer {
  display: -webkit-flex;
  display: flex;
  margin: 20px;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.checkout_totalInfo {
  margin: 10px;
  padding: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 300px;
}

.checkout_totalInfo > * {
  margin-top: 5px;
  margin-bottom: 5px;
}

.checkout__productList {
  width: 100%;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .checkout__productsContainer {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .checkout__heading {
    font-size: 2em;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .checkout__heading {
    font-size: 2.2em;
  }
  .checkout__productsContainer {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .checkout__checkoutEmptyLine1 {
    font-size: 1em;
  }
  .checkout__checkoutEmptyLine2 {
    font-size: 0.8em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .checkout__heading {
    font-size: 2.3em;
  }
}


.checkout__userDataFormScreen {
  position: absolute;
  background-color:rgba(0,0,0,0.7);
  top:0px;
  z-index:4;
  padding-top:100px;
  height: 100%;
  width: 100%;
}
.checkoutProduct__container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0px 0px 8px lightgray;
  border-radius: 15px;
  padding: 10px;
  margin: 10px;
}

.checkoutProduct__title {
  font-size: 1.1em;
}

.checkoutProduct__product {
  width: 85%;
}

.checkoutProduct__price {
  font-size: 1.5em;
  color: #d8024d;
  margin-top: 10px;
  font-family: "Oswald", sans-serif;
}

.checkoutProduct__qty {
  font-size: 1.5em;
  font-weight: bold;
  color: darkblue;
  font-family: "Open Sans", sans-serif;
}

.checkoutProduct__product {
  display: -webkit-flex;
  display: flex;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .checkoutProduct__title {
    font-size: 1em;
  }
  .checkoutProduct__price {
    font-size: 1.2em;
  }
  .checkoutProduct__qty {
    font-size: 1.2em;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .checkoutProduct__title {
    font-size: 1em;
  }
  .checkoutProduct__price {
    font-size: 1.2em;
  }
  .checkoutProduct__qty {
    font-size: 1.2em;
  }
}

.userDataForm {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
}

.userDataForm__form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: stretch;
          align-items: stretch;
  margin-top: 20px;
  width: 350px;
  border-radius: 20px;
  height: 580px;
  padding: 20px;
  background-color: rgb(250, 250, 250);
  box-shadow: 1px 1px 5px 1px lightgray;
  margin-bottom: 20px;
}

.userDataForm__logo {
  max-width: 300px;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .userDataForm__form {
    width: 80%;
  }
  
  .userDataForm__logo {
    width: 190px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 1200px) and (max-width: 1399px) {
}

@media (min-width: 1400px) {
}

.printOrderUtil {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 40px 0px;
}
.printOrderUtil__input {
  margin: 10px 0px;
  font-size: 1.4em;
  padding: 10px;
  border-radius: 5px;
  color: black;
}

.deals {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 20px;
  padding-bottom: 40px;
  font-family: "Josefin Sans", sans-serif;
}

.deals__header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 15px;
  padding: 0px 20px;
}

.deals__banner {
  width: 100%;
}

.deals table {
  font-family: "Roboto", sans-serif !important;
}

.deals__content {
  padding: 0px 20px;
}

.deals
  .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  width: 100% !important;
}

.deals .td-padded {
  padding: 0px 10px;
  text-align: center;
}
.aboutus {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
}
.servicesPage__banner {
  width: 100%;
  -webkit-filter: brightness(1);
          filter: brightness(1);
  position: -webkit-sticky;
  position: sticky;
}
.servicesPage__description {
  font-size: 1.2em;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: justify;
  font-family: "Open sans";
}

.servicesPage h2 {
  text-align: center;
  font-family: "josefin sans";
}

.servicesPage__getButtonContainer {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

