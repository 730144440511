.cartBoxOnPage {
  position: absolute !important;
  height: 385px;
  width: 300px;
  background-color: white;
  top: 60px;
  right: 30px;
  padding: 12px;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.cartBox__item {
  display: flex;
}
.cartBox__item .price {
  color: rgb(163, 32, 65);
}
.cartBox__item img {
  width: 40px;
  height: 70px;
  margin-right: 5px;
}

.cartItemsContainer {
  overflow-y: scroll;
  height: 293px;
  margin-bottom: 7px;
}
.yourCart {
  color: rgb(163, 32, 65);
  margin-left: 10px;
}
