.popupModal {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  z-index: 2000;
  background-color: #0000008e;
  font-family: "Roboto";
}

.popupModal__box {
  border-radius: 20px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 12px;
  max-width: 545px;
  box-shadow: 0px 0px 25px black;
}

.popupModal__box span {
  font-weight: bold;
  margin: 0px 10px;
}
