.searchResults__container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px;
}

.searchResults__container > * {
  display: inline-block;
}

.searchResults__notFound {
  display: flex;
  font-size: 1.9em;
  align-items: center;
  justify-content: center;
  margin: 80px 0px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.searchResults__loading {
  display: flex;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 200px;
}