.header {
  width: 100%;
  position: sticky;
  background-color: #f9f9f9;
  z-index: 5;
  top: 0;
}

.header__containerOne {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.header__containerOne .header__iconContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
}

.header__containerOne .header__iconContainer div,
.header__xsSideMenuIcon Button div {
  display: flex;
  color: #2d3436;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 0.8em;
  margin-top: 3px;
}

.header__containerTwo div {
  background-color: #2d3436;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.header__containerTwo Button {
  color: white;
}

.header__logo {
  width: 300px;
  margin-top: 8px;
  margin-bottom: 3px;
}

.header__containerSearch {
  border-radius: 30px;
  margin-bottom: 5px;
  padding: 0px 5px;
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  border: 2px solid lightgray;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}

@keyframes search-bar-anim-open {
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    height: 0px;
    display: none;
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    height: 28px;
    display: block;
  }
}

@keyframes search-bar-anim-close {
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    height: 0px;
    display: block;
  }
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    height: 28px;
    display: none;
  }
}

.header__xsSideMenuIcon {
  display: none;
  width: 100%;
  color: #2d3436;
}

.header__xsIconContainer {
  align-items: center;
  display: none;
}

.header__drawerInner div {
  color: #2d3436;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .header__xsIconContainer {
    display: flex;
  }
  .header__logo {
    width: 100%;
    margin-left: 5px;
  }
  .header__containerOne .header__iconContainer {
    display: none;
  }
  .header__containerTwo {
    display: none;
  }
  .header__containerSearch {
    margin-left: 0px;
    margin-right: 0px;
  }
  .header__xsSideMenuIcon {
    display: block;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .header__logo {
    width: 275px;
  }
  .header__containerOne .header__iconContainer div {
    font-size: 0.7em;
  }
  .header__containerSearch {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .header__containerSearch {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .header__containerSearch {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .header__containerSearch {
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media (min-width: 1400px) {
  .header__containerSearch {
    margin-left: 100px;
    margin-right: 100px;
  }
}

.alertLine {
  color: white !important;
  text-align: center;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  top: 0px;
  position: sticky;
  z-index: 1400;
}

@media (max-width: 767px) {
  .cartBoxOnPageContainer {
    display: none !important;
  }
}
