.headerMenuItem span {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  display: flex;
  align-items: flex-end;
  justify-self: center;
  font-size: 1.2em;
  background-color: transparent;
  transition: all 0.4s;
}

@media (min-width: 576px) and (max-width: 767px) {
  .headerMenuItem span {
    font-size: 0.9em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .headerMenuItem span {
    font-size: 1em;
  }
}

.headerMenuItem:hover {
  background-color: rgb(9, 132, 227);
  transition:all 0.4s;
}
