.deals {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 40px;
  font-family: "Josefin Sans", sans-serif;
}

.deals__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  padding: 0px 20px;
}

.deals__banner {
  width: 100%;
}

.deals table {
  font-family: "Roboto", sans-serif !important;
}

.deals__content {
  padding: 0px 20px;
}

.deals
  .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  width: 100% !important;
}

.deals .td-padded {
  padding: 0px 10px;
  text-align: center;
}