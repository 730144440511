.printOrderUtil {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0px;
}
.printOrderUtil__input {
  margin: 10px 0px;
  font-size: 1.4em;
  padding: 10px;
  border-radius: 5px;
  color: black;
}
