.cart__heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5em;
  color: #d8024d;
  font-family: "Oswald", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 25px;
}

.cart__heading span {
  border-bottom: 2px solid #d8024d;
}

.cart__cartEmpty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cart__cartEmptyLine1 {
  font-size: 2em;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
}

.cart__cartEmptyLine2 {
  font-size: 1.8em;
  text-shadow: 2px 2px 1px #ce7b98;
  font-family: "Josefin Sans", sans-serif;
  cursor: pointer;
}

.cart__cartEmptyLine2:hover {
  font-weight: bold;
}

.cart__loading {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 100px;
}

.cart_actionBtnContainer {
  display: flex;
  justify-content: space-evenly;
}

.cart_actionBtnContainer > * {
  width: 140px;
}

.cart__productsContainer {
  display: flex;
  margin: 20px;
  align-items: flex-start;
}

.cart_subtotalInfo {
  margin: 10px;
  padding: 12px;
  background-color: #eeeeee;
  border-radius: 10px;
  box-shadow: 0px 0px 10px lightgray;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  max-width: 300px;
}

.cart_subtotalInfo > * {
  margin-top: 5px;
  margin-bottom: 5px;
}

.cart__productList {
  width: 100%;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .cart__heading {
    font-size: 2em;
  }
  .cart__cartEmptyLine1 {
    font-size: 1em;
  }
  .cart__cartEmptyLine2 {
    font-size: 0.8em;
  }
  .cart__productsContainer {
    flex-direction: column;
  }
  .cart_subtotalInfo {
    margin: 2px;
    padding: 6px;
    border-radius: 8px;
    max-width: 260px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .cart__heading {
    font-size: 2.3em;
  }
  .cart__productsContainer {
    flex-direction: column;
  }
}