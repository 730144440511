.codefumesIcon__svgIcon > .cls-1 {
  fill: none;
  stroke: #010101;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.codefumesIcon__svgIcon > .cls-2 {
  fill: #010101;
}
.codefumesIcon__svgIcon > .cls-3 {
  isolation: isolate;
  font-size: 15.68px;
  fill: #fff;
  font-family: Consolas-Bold, Consolas;
  font-weight: 700;
}

.codefumesIcon__svgIcon {
  display: inline;
}
