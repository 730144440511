.footerSmall__codefumesAttribution,
.footerSmall__codefumesAttribution > strong > a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerSmall__codefumesAttribution > strong > a {
  opacity: 0.95;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.1em;
  text-transform: uppercase;
}

.footerSmall__codefumesAttribution > strong > a:hover {
  opacity: 1;
}
