.homeBookListCarousel {
  margin-bottom: 15px;
}

.homeBookListCarousel__label {
  text-shadow: 1px 1px 1px gray;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.4em;
  text-transform: uppercase;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .homeBookListCarousel__label {
    font-size: 0.9em;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .homeBookListCarousel__label {
    font-size: 1em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .homeBookListCarousel__label {
    font-size: 1.1em;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .homeBookListCarousel__label {
    font-size: 1.3em;
  }
}