.ProductCard {
  width: 270px;
  height: 170px;
  font-family: "Roboto", sans-serif;
  box-shadow: 0px 0px 8px gray;
  border-radius: 7px;
  margin: 10px;
  padding: 10px;
  background-color: white;
}

.ProductCard__container {
  display: flex;
  width: 100%;
  height: 100%;
}

.ProductCard__btn {
  font-family: "Oswald", sans-serif;
}

.link {
  text-decoration: none;
  color: inherit;
}

.ProductCard__detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
  max-width: 60%;
}

.ProductCard__img {
  width: 40%;
}

.ProductCard__img img {
  width: 100%;
  height: 100%;
}

.ProductCard .ProductCard__title {
  color: #2d3436;
  font-size: 1em;
}

.ProductCard .ProductCard__author {
  color: #636e72;
  font-size: 0.9em;
}

.ProductCard .ProductCard__title,
.ProductCard .ProductCard__author {
  font-family: "Roboto", sans-serif;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .ProductCard {
    width: 160px;
    height: 120px;
  }
  .ProductCard .ProductCard__title {
    font-size: 0.7em;
  }
  .ProductCard .ProductCard__author {
    font-size: 0.6em;
  }
  .ProductCard__btn {
    font-size: 0.7em;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .ProductCard {
    width: 180px;
    height: 120px;
  }
  .ProductCard .ProductCard__title {
    font-size: 0.8em;
  }
  .ProductCard .ProductCard__author {
    font-size: 0.7em;
  }
  .ProductCard__btn {
    font-size: 0.8em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .ProductCard {
    width: 200px;
    height: 120px;
  }
  .ProductCard .ProductCard__title {
    font-size: 0.8em;
  }
  .ProductCard .ProductCard__author {
    font-size: 0.7em;
  }
  .ProductCard__btn {
    font-size: 0.8em;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ProductCard {
    width: 230px;
    height: 140px;
  }
  .ProductCard .ProductCard__title {
    font-size: 0.9em;
  }
  .ProductCard .ProductCard__author {
    font-size: 0.8em;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
}

@media (min-width: 1400px) {
}
