.userDataForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
}

.userDataForm__form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  margin-top: 20px;
  width: 350px;
  border-radius: 20px;
  height: 580px;
  padding: 20px;
  background-color: rgb(250, 250, 250);
  box-shadow: 1px 1px 5px 1px lightgray;
  margin-bottom: 20px;
}

.userDataForm__logo {
  max-width: 300px;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .userDataForm__form {
    width: 80%;
  }
  
  .userDataForm__logo {
    width: 190px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 1200px) and (max-width: 1399px) {
}

@media (min-width: 1400px) {
}
