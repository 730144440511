.checkoutProduct__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 8px lightgray;
  border-radius: 15px;
  padding: 10px;
  margin: 10px;
}

.checkoutProduct__title {
  font-size: 1.1em;
}

.checkoutProduct__product {
  width: 85%;
}

.checkoutProduct__price {
  font-size: 1.5em;
  color: #d8024d;
  margin-top: 10px;
  font-family: "Oswald", sans-serif;
}

.checkoutProduct__qty {
  font-size: 1.5em;
  font-weight: bold;
  color: darkblue;
  font-family: "Open Sans", sans-serif;
}

.checkoutProduct__product {
  display: flex;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .checkoutProduct__title {
    font-size: 1em;
  }
  .checkoutProduct__price {
    font-size: 1.2em;
  }
  .checkoutProduct__qty {
    font-size: 1.2em;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .checkoutProduct__title {
    font-size: 1em;
  }
  .checkoutProduct__price {
    font-size: 1.2em;
  }
  .checkoutProduct__qty {
    font-size: 1.2em;
  }
}
