.home {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.home__carouselItem img {
  width: 100%;
}

.home__bookCarouselLabelHeading {
  text-shadow: 1px 1px 1px gray;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.4em;
}

.home__getRecommendationContainer,
.home__authorOfTheMonthContainer,
.home__giveReviewContainer,
.home__requestBookContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  padding: 40px 0px;
  font-family: "Josefin Sans";
  margin-bottom: 20px;
}

.home__getRecommendationContainer {
  background: url(../../assets/img/home_parallax_bg_1.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home__authorOfTheMonthContainer {
  background: url(../../assets/img/home_parallax_bg_2.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home__giveReviewContainer {
  background: url(../../assets/img/home_parallax_bg_3.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home__requestBookContainer {
  background: url(../../assets/img/home_parallax_bg_4.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home__getRecommendationContainer .home__heading,
.home__authorOfTheMonthContainer .home__heading,
.home__giveReviewContainer .home__heading,
.home__requestBookContainer .home__heading {
  font-size: 3em;
  text-align: center;
  font-weight: bold;
}

.home__getRecommendationContainer p,
.home__giveReviewContainer p,
.home__requestBookContainer p {
  font-size: 2em;
  margin-top: 10px;
  text-align: center;
}

.home__partedBoxButton {
  font-size: 1.4em;
}

.home__authorOfTheMonthContainer .home__authorOfTheMonthImg {
  max-width: 200px;
  clip-path: ellipse(50% 50% at 50% 50%);
  border: 4px solid lightgray;
}

.home__authorInfoContainer {
  display: flex;
  width: 40%;
}

.home__authorInfoContainer > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.home__authorInfoContainer .home__authorOfTheMonthName {
  font-size: 1.8em;
}

.home__authorInfoContainer .home__authorOfTheMonthDescription {
  font-size: 1.2em;
  line-height: 135%;
}

.home__authorBooksContainer {
  width: 60%;
}

.home__authorOfTheMonthContainer > div {
  width: 98%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

.HomePublisherItem {
  width: 180px;
  height: 180px;
  padding: 7px;
  border: 2px solid lightgray;
  border-radius: 25px;
}

.HomePublisherItem img {
  width: 100%;
  height: 100%;
}

.home__needHelpContactUs {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.home__needHelpContactUs span {
  font-size: 1.8em;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .home__bookCarouselLabelHeading {
    font-size: 0.9em;
  }
  .home__getRecommendationContainer .home__heading,
  .home__authorOfTheMonthContainer .home__heading,
  .home__giveReviewContainer .home__heading,
  .home__requestBookContainer .home__heading {
    font-size: 1.6em;
  }

  .home__getRecommendationContainer p,
  .home__giveReviewContainer p,
  .home__requestBookContainer p {
    font-size: 1em;
  }
  .home__partedBoxButton {
    font-size: 0.8em;
  }
  .home__authorInfoContainer .home__authorOfTheMonthName {
    font-size: 1.2em;
  }
  .home__authorInfoContainer .home__authorOfTheMonthDescription {
    font-size: 0.8em;
  }
  .home__authorOfTheMonthContainer > div {
    flex-direction: column;
  }
  .home__authorBooksContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .home__authorInfoContainer {
    width: 95%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
  }
  .home__authorOfTheMonthContainer .home__authorOfTheMonthImg {
    width: 90px;
  }
  .HomePublisherItem {
    width: 90px;
    height: 90px;
  }
  .home__needHelpContactUs span {
    font-size: 1.2em;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .home__bookCarouselLabelHeading {
    font-size: 1em;
  }
  .home__getRecommendationContainer .home__heading,
  .home__authorOfTheMonthContainer .home__heading,
  .home__giveReviewContainer .home__heading,
  .home__requestBookContainer .home__heading {
    font-size: 2.5em;
  }

  .home__getRecommendationContainer p,
  .home__giveReviewContainer p,
  .home__requestBookContainer p {
    font-size: 1.4em;
  }
  .home__partedBoxButton {
    font-size: 1em;
  }
  .home__authorInfoContainer .home__authorOfTheMonthName {
    font-size: 1.4em;
  }
  .home__authorInfoContainer .home__authorOfTheMonthDescription {
    font-size: 0.9em;
  }
  .home__authorOfTheMonthContainer > div {
    flex-direction: column;
  }
  .home__authorBooksContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .home__authorInfoContainer {
    width: 95%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
  }
  .home__authorOfTheMonthContainer .home__authorOfTheMonthImg {
    width: 150px;
  }
  .HomePublisherItem {
    width: 120px;
    height: 120px;
  }
  .home__needHelpContactUs span {
    font-size: 1.4em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .home__bookCarouselLabelHeading {
    font-size: 1.1em;
  }
  .home__getRecommendationContainer .home__heading,
  .home__authorOfTheMonthContainer .home__heading,
  .home__giveReviewContainer .home__heading,
  .home__requestBookContainer .home__heading {
    font-size: 2.6em;
  }

  .home__getRecommendationContainer p,
  .home__giveReviewContainer p,
  .home__requestBookContainer p {
    font-size: 1.6em;
  }
  .home__partedBoxButton {
    font-size: 1.2em;
  }
  .home__authorInfoContainer .home__authorOfTheMonthName {
    font-size: 1.5em;
  }
  .home__authorInfoContainer .home__authorOfTheMonthDescription {
    font-size: 1.1em;
  }
  .home__authorBooksContainer {
    width: 50%;
  }
  .home__authorInfoContainer {
    width: 50%;
  }
  .HomePublisherItem {
    width: 150px;
    height: 150px;
  }
  .home__needHelpContactUs span {
    font-size: 1.6em;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home__bookCarouselLabelHeading {
    font-size: 1.3em;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
}

@media (min-width: 1400px) {
}
