.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Century Gothic";
  margin-top: 20px;
  width: 100%;
}

.footer__containerOne {
  background-color: #b30d2b;
  color: white;
  width: 100%;
  padding: 20px 0px;
  margin-bottom: 10px;
}

.footer__containerOne .footer__featuresContainer a,
.footer__containerOne .footer__contactContainer div {
  margin: 4px 0px;
}

.footer__containerOne .footer__mapContainer {
  align-items: flex-start;
}

.footer__containerOne .footer__contactContainer {
  width: 80%;
}

.footer__containerOne .footer__featuresContainer,
.footer__containerOne .footer__mapContainer,
.footer__containerOne .footer__contactContainer {
  display: flex;
  flex-direction: column;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer .footer__containerHeading {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.footer__containerOne .footer__contactContainer .footer__contactSocialMedia {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 2em;
}

.footer__containerOne a:hover {
  color: #d1d1d1;
}

.footer__mapContainer div img {
  width: 100%;
}

.footer__containerOne .footer__featuresContainer {
  margin-left: 50px;
}

@media (max-width: 599px) {
  .footer__containerOne .footer__featuresContainer a,
  .footer__containerHeading {
    text-align: center;
  }
  .footer__containerOne .footer__featuresContainer {
    margin-left: 0px;
  }
  .footer__containerOne .footer__contactContainer,
  .footer__containerHeading {
    width: 100%;
  }
}
