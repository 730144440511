.checkout__heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5em;
  color: #d8024d;
  font-family: "Oswald", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 25px;
}

.checkout__heading span {
  border-bottom: 2px solid #d8024d;
}

.checkout__checkoutEmpty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkout__checkoutEmptyLine1 {
  font-size: 2em;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
}

.checkout__checkoutEmptyLine2 {
  font-size: 1.8em;
  text-shadow: 2px 2px 1px #ce7b98;
  font-family: "Josefin Sans", sans-serif;
  cursor: pointer;
}

.checkout__checkoutEmptyLine2:hover {
  font-weight: bold;
}

.checkout__loading {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 100px;
}

.checkout_actionBtnContainer {
  display: flex;
  justify-content: space-around;
}

.checkout_actionBtnContainer > * {
  width: 140px;
}

.checkout__productsContainer {
  display: flex;
  margin: 20px;
  align-items: flex-start;
}

.checkout_totalInfo {
  margin: 10px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  max-width: 300px;
}

.checkout_totalInfo > * {
  margin-top: 5px;
  margin-bottom: 5px;
}

.checkout__productList {
  width: 100%;
}

/* MEDIA QUERIES */
@media (max-width: 575px) {
  .checkout__productsContainer {
    flex-direction: column;
  }
  .checkout__heading {
    font-size: 2em;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .checkout__heading {
    font-size: 2.2em;
  }
  .checkout__productsContainer {
    flex-direction: column;
  }
  .checkout__checkoutEmptyLine1 {
    font-size: 1em;
  }
  .checkout__checkoutEmptyLine2 {
    font-size: 0.8em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .checkout__heading {
    font-size: 2.3em;
  }
}


.checkout__userDataFormScreen {
  position: absolute;
  background-color:rgba(0,0,0,0.7);
  top:0px;
  z-index:4;
  padding-top:100px;
  height: 100%;
  width: 100%;
}