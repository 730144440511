.aboutus {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
}